<template>
  <MobileLayoutsHome>
    <template #header>
      <BannerSection />
    </template>
    <template #main>
      <div class="home">
        <div class="game-wrapper">
          <div class="game-wrapper__content" :style="heightContent">
            <div id="target_element" class="game-wrapper__content--left">
              <div ref="listRef" class="group-tab">
                <div
                  v-for="tab in filteredData"
                  ref="itemRef"
                  :key="tab.key"
                  class="tab-link"
                  :class="[
                    tab.key,
                    {
                      active:
                        (tab.key === $route?.params?.type && tab?.active === $route?.name) || (tab.key === 'sports' && !$route?.params?.type) || ($route?.name === 'livecasino-type' && tab?.active === $route?.name)
                    }
                  ]"
                  @click="handleRedirectPage(tab)"
                >
                  <BaseImg
                    :src="
                      (tab.key === $route?.params?.type && tab?.active === $route?.name) || (tab.key === 'sports' && !$route?.params?.type) || ($route?.name === 'livecasino-type' && tab?.active === $route?.name)
                        ? tab.iconImage
                        : tab.iconImageDefault
                    "
                  />
                  <div v-html="tab.title" />
                </div>
              </div>
            </div>
            <div ref="listRightRef" class="game-wrapper__content--right">
              <nuxt-page />
            </div>
          </div>
        </div>
      </div>
    </template>
  </MobileLayoutsHome>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import BannerSection from '@/components/mobile/pages/home/hero-banner'
import MobileLayoutsHome from '~/components/mobile/layouts/home.vue'
import { useAppStore } from '@/store/app'
import { getLeftMenu } from '~/resources/menu-left'
import BaseLoading from '@/components/common/base-loading.vue'
import { DEFAULT_GAME } from '~/constants'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
import { URLS } from '~/config/page-url'
const { isBotChromeLighthouse } = useAppStore()
const { isLoading, totalItem, fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const route = useRoute()
const router = useRouter()
const { TABS } = getLeftMenu()
const listRef = ref(null)
const itemRef = ref(null)
const listRightRef = ref(null)
const { $device, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const heightContent = ref(0)
const filteredData = ref([])
const getHeight = () => {
  const designRatio = 375 / 112
  const bannerHeight = window.innerWidth / designRatio
  const headerHeight = document.querySelector('.header')?.offsetHeight || 0
  const footerHeight = document.querySelector('.wrapper-menu-bottom')?.offsetHeight || 0
  const paddingTop = 16
  const height = `${window.innerHeight - bannerHeight - headerHeight - footerHeight - paddingTop}px`
  return { height }
}
const handleRedirectPage = (tab) => {
  const path = tab.active === 'livecasino-type' ? `/livecasino/${tab.key}` : (tab.key === 'sports' ? URLS.LIVESPORT : `/game/${tab.key}`)
  router.push(path)
}
const isIOS = () => {
  const ua = navigator.userAgent
  return /iPhone|iPad|iPod/i.test(ua)
}
const scrollToActive = (delay = 50, index) => {
  setTimeout(() => {
    const item = itemRef.value[index]
    item.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, delay)
}
const autoScrollToIndex = (delay = 100) => {
  filteredData.value.forEach((e, index) => {
    if (e.key === route?.params?.type) {
      scrollToActive(delay, index)
    }
  })
}
watch(route, () => {
  if (route.fullPath === '/') {
    router.push(URLS.LIVESPORT)
  }
  autoScrollToIndex()
})
watchEffect(() => {
  if (!isLogged.value) {
    filteredData.value = TABS?.filter((item) => item.key !== 'recently')
  } else {
    filteredData.value = TABS
  }
})
onMounted(() => {
  fetchCongGame()
  fetchGameCasino()
  if (route.fullPath === '/') {
    router.push(URLS.LIVESPORT)
  }
  setTimeout(() => {
    heightContent.value = getHeight()
  }, 500)
  autoScrollToIndex(500)
  if (isIOS()) {
    document.querySelector('body').classList.add('fix-screen')
  }
})
onBeforeUnmount(() => {
  if (isIOS()) {
    document.querySelector('body').classList.remove('fix-screen')
  }
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/index.scss"></style>
